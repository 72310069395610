import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

class FeaturesTiles extends React.Component {
  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'features-tiles section center-content',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'features-tiles-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const tilesClasses = classNames('tiles-wrap', pushLeft && 'push-left');

    const sectionHeader = {
      title: 'Feature Highlights',
      paragraph:
        'The CRANE tool was built for anyone assessing the impact potential of their investments – early-stage investors, incubators, accelerators, government agencies, large corporations, philanthropists and more.',
    };

    return (
      <section {...props} className={outerClasses}>
        <div className='container'>
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className='center-content' />
            <div className={tilesClasses}>
              <div
                className='tiles-item reveal-from-bottom'
                data-reveal-container='.tiles-wrap'
              >
                <div className='tiles-item-inner'>
                  <div className='features-tiles-item-header'>
                    <div className='features-tiles-item-image mb-16'>
                      <Image
                        src={
                          require('./../../assets/images/feature-tile-icon-01.svg')
                            .default
                        }
                        alt='Features tile icon 01'
                        width={72}
                        height={72}
                      />
                    </div>
                  </div>
                  <div className='features-tiles-item-content'>
                    <h4 className='mt-0 mb-8'>Comprehensive Taxonomy</h4>
                    <p className='m-0 text-sm'>
                      The CRANE tool’s goal is to provide users with as many
                      technology solutions as possible, covering most industries
                      that touch greenhouse gas emissions.
                    </p>
                  </div>
                </div>
              </div>

              <div
                className='tiles-item reveal-from-bottom'
                data-reveal-container='.tiles-wrap'
                data-reveal-delay='100'
              >
                <div className='tiles-item-inner'>
                  <div className='features-tiles-item-header'>
                    <div className='features-tiles-item-image mb-16'>
                      <Image
                        src={
                          require('./../../assets/images/feature-tile-icon-02.svg')
                            .default
                        }
                        alt='Features tile icon 02'
                        width={72}
                        height={72}
                      />
                    </div>
                  </div>
                  <div className='features-tiles-item-content'>
                    <h4 className='mt-0 mb-8'>Straightforward Inputs</h4>
                    <p className='m-0 text-sm'>
                      The CRANE tool requires a short list of input assumptions
                      from you. 
                      Users can learn more about what to bring with
                      you <a href='https://coda.io/@cranetool/crane-wiki' target='_blank' rel='noopener noreferrer'>here.</a>
                    </p>
                  </div>
                </div>
              </div>

              <div
                className='tiles-item reveal-from-bottom'
                data-reveal-container='.tiles-wrap'
                data-reveal-delay='200'
              >
                <div className='tiles-item-inner'>
                  <div className='features-tiles-item-header'>
                    <div className='features-tiles-item-image mb-16'>
                      <Image
                        src={
                          require('./../../assets/images/feature-tile-icon-04.svg')
                            .default
                        }
                        alt='Features tile icon 03'
                        width={72}
                        height={72}
                      />
                    </div>
                  </div>
                  <div className='features-tiles-item-content'>
                    <h4 className='mt-0 mb-8'>Robust Methodology</h4>
                    <p className='m-0 text-sm'>
                      The CRANE tool aggregates data for you so you don’t need
                      to reinvent the wheel every time you want to assess
                      climate impact for a new technology.
                    </p>
                  </div>
                </div>
              </div>

              <div
                className='tiles-item reveal-from-bottom'
                data-reveal-container='.tiles-wrap'
                data-reveal-delay='300'
              >
                <div className='tiles-item-inner'>
                  <div className='features-tiles-item-header'>
                    <div className='features-tiles-item-image mb-16'>
                      <Image
                        src={
                          require('./../../assets/images/feature-tile-icon-03.svg')
                            .default
                        }
                        alt='Features tile icon 04'
                        width={72}
                        height={72}
                      />
                    </div>
                  </div>
                  <div className='features-tiles-item-content'>
                    <h4 className='mt-0 mb-8'>Transparent Calculations</h4>
                    <p className='m-0 text-sm'>
                      The CRANE tool provides a summary of every single
                      calculation and data source that goes into making its
                      helpful data visuals.
                    </p>
                  </div>
                </div>
              </div>

              <div
                className='tiles-item reveal-from-bottom'
                data-reveal-container='.tiles-wrap'
                data-reveal-delay='400'
              >
                <div className='tiles-item-inner'>
                  <div className='features-tiles-item-header'>
                    <div className='features-tiles-item-image mb-16'>
                      <Image
                        src={
                          require('./../../assets/images/feature-tile-icon-05.svg')
                            .default
                        }
                        alt='Features tile icon 05'
                        width={72}
                        height={72}
                      />
                    </div>
                  </div>
                  <div className='features-tiles-item-content'>
                    <h4 className='mt-0 mb-8'>Easy-to-Share Scenarios</h4>
                    <p className='m-0 text-sm'>
                      You can download <code>JSON</code> files of each report
                      you run so you can revisit it next time you use CRANE. You
                      can also send these JSON files to your colleagues that
                      might want to start from the same input assumptions you
                      used.
                    </p>
                  </div>
                </div>
              </div>

              <div
                className='tiles-item reveal-from-bottom'
                data-reveal-container='.tiles-wrap'
                data-reveal-delay='500'
              >
                <div className='tiles-item-inner'>
                  <div className='features-tiles-item-header'>
                    <div className='features-tiles-item-image mb-16'>
                      <Image
                        src={
                          require('./../../assets/images/feature-tile-icon-06.svg')
                            .default
                        }
                        alt='Features tile icon 06'
                        width={72}
                        height={72}
                      />
                    </div>
                  </div>
                  <div className='features-tiles-item-content'>
                    <h4 className='mt-0 mb-8'>Standard Output Reports</h4>
                    <p className='m-0 text-sm'>
                      You can choose to view annual impact potential, cumulative
                      impact potential, market penetration, and key calculation
                      factors.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;
