import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";

const propTypes = {
  ...SectionTilesProps.types
};

const defaultProps = {
  ...SectionTilesProps.defaults
};

class Testimonial extends React.Component {
  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      ...props
    } = this.props;

    const outerClasses = classNames(
      "testimonial section",
      topOuterDivider && "has-top-divider",
      bottomOuterDivider && "has-bottom-divider",
      hasBgColor && "has-bg-color",
      invertColor && "invert-color",
      className
    );

    const innerClasses = classNames(
      "testimonial-inner section-inner",
      topDivider && "has-top-divider",
      bottomDivider && "has-bottom-divider"
    );

    const tilesClasses = classNames("tiles-wrap", pushLeft && "push-left");

    const sectionHeader = {
      title: "User Testimonials",
      paragraph:
        "Here’s what CRANE users are saying about the tool itself and how they are using it to benefit their organizations."
    };

    return (
      <section {...props} className={outerClasses}>
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content" />
            <div className={tilesClasses}>
              <div
                className="tiles-item reveal-from-bottom"
                data-reveal-container=".tiles-wrap"
              >
                <div className="tiles-item-inner has-shadow">
                  <div className="testimonial-item-header mb-16">
                    <div className="testimonial-item-image">
                      <Image
                        src={require("./../../assets/images/testimonial-image-01.jpg").default}
                        alt="Testimonial 01"
                        width={48}
                        height={48}
                      />
                    </div>
                  </div>
                  <div className="testimonial-item-content">
                    <p className="text-sm mb-0">
                      — Elemental Excelerator is excited to use CRANE as almost
                      a ‘common app’ for startups in our pipeline and portfolio
                      to calculate their climate impact without specialized
                      consultants or bespoke analyses. It’s also helpful for us
                      to be on the same page with co-investors.
                    </p>
                  </div>
                  <div className="testimonial-item-footer text-xs fw-500 mt-32 mb-0 has-top-divider">
                    <span className="testimonial-item-name">Dawn Lippert</span>
                    <span className="text-color-low"> - </span>
                    <span className="testimonial-item-link">
                      Elemental Excelerator
                    </span>
                  </div>
                </div>
              </div>

              <div
                className="tiles-item reveal-from-bottom"
                data-reveal-container=".tiles-wrap"
                data-reveal-delay="200"
              >
                <div className="tiles-item-inner has-shadow">
                  <div className="testimonial-item-header mb-16">
                    <div className="testimonial-item-image">
                      <Image
                        src={require("./../../assets/images/testimonial-image-02.jpg").default}
                        alt="Testimonial 02"
                        width={48}
                        height={48}
                      />
                    </div>
                  </div>
                  <div className="testimonial-item-content">
                    <p className="text-sm mb-0">
                      — Breakthrough Energy supports innovations that get the
                      world to net-zero greenhouse gas emissions. CRANE makes it
                      possible for investors, governments, and innovators to
                      identify transformational solutions to avoid a climate
                      catastrophe.
                    </p>
                  </div>
                  <div className="testimonial-item-footer text-xs fw-500 mt-32 mb-0 has-top-divider">
                    <span className="testimonial-item-name">Jonah Goldman</span>
                    <span className="text-color-low"> - </span>
                    <span className="testimonial-item-link">
                      Breakthrough Energy
                    </span>
                  </div>
                </div>
              </div>

              <div
                className="tiles-item reveal-from-bottom"
                data-reveal-container=".tiles-wrap"
                data-reveal-delay="400"
              >
                <div className="tiles-item-inner has-shadow">
                  <div className="testimonial-item-header mb-16">
                    <div className="testimonial-item-image">
                      <Image
                        src={require("./../../assets/images/testimonial-image-03.jpg").default}
                        alt="Testimonial 03"
                        width={48}
                        height={48}
                      />
                    </div>
                  </div>
                  <div className="testimonial-item-content">
                    <p className="text-sm mb-0">
                      — Prime Coalition will use CRANE not only to assess companies pre-investment to make sure each one clears the 'gigaton-scale emissions reduction potential' target included in our downselection criteria, but we'll also rely on CRANE to help us track progress against climate milestones for each company post-investment.
                    </p>
                  </div>
                  <div className="testimonial-item-footer text-xs fw-500 mt-32 mb-0 has-top-divider">
                    <span className="testimonial-item-name">Sarah Kearney</span>
                    <span className="text-color-low"> - </span>
                    <span className="testimonial-item-link">
                        Prime Coalition
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

Testimonial.propTypes = propTypes;
Testimonial.defaultProps = defaultProps;

export default Testimonial;
