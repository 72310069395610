import React, { useMemo, useState } from "react";
import classNames from "classnames";
import SectionHeader from "./partials/SectionHeader";
import Pagination from "../elements/Pagination";
import data from "../../articles.json";

const PageSize = 6;

function NewsTiles(props) {
  const { topDivider, bottomDivider, pushLeft } = props;

  const innerClasses = classNames(
    "team-inner section-inner pt-0",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider",
    "mt-32"
  );

  const tilesClasses = classNames("tiles-wrap", pushLeft && "push-left");

  const sectionHeader = {
    title: "The CRANE Tool in the News",
    paragraph:
      "Check out some of the recent articles that highlight the potential and capabilities of the CRANE Tool",
  };

  const [currentPage, setCurrentPage] = useState(1);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return data
      .sort(function (a, b) {
        return b.id - a.id;
      })
      .slice(firstPageIndex, lastPageIndex);
  }, [currentPage]);

  return (
    <section {...props} className={"team section"}>
      <div className="container mt-32">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <br />
          <div className={tilesClasses}>
            {currentTableData.map((article) => {
              return (
                <div key={article.id} className="tiles-item">
                  <div className="tiles-item-inner">
                    <div className="team-item-header flex-shrink-0">
                      <div
                        className="team-item-image mb-24 w-[320px] h-[180px] bg-cover bg-center"
                        style={{
                          backgroundImage: `url(${
                            require(`../../assets/images/${article.imgURL}`)
                              .default
                          })`,
                          width: "320px",
                          height: "180px",
                        }}
                      ></div>
                    </div>
                    <div className="team-item-content flex-grow">
                      <h5 className="team-item-name mt-0 mb-4">
                        {article.title}
                      </h5>
                      <div className="team-item-role text-xs fw-500 mb-8">
                        by {article.author}
                      </div>
                      <p className="m-0 text-sm">
                        {article.blurb}...{" "}
                        <a
                          href={article.link}
                          target="_blank"
                          rel="noreferrer"
                          className="text-color-primary"
                        >
                          Read More
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div
        className="container center-content mt-16 mb-32"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={data.length}
          pageSize={PageSize}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </div>
    </section>
  );
}

export default NewsTiles;
